<template>
  <div class="accordion-item">
    <div class="settings">
      <Multiselect
        v-model="localItem.model"
        label="Model"
        :options="modelsOptions"
        :disabled="isPromptStreaming"
        :get-label-callback="id => getLabel(id)"
        @input="onChange"
      />
      <TextField v-model="localItem.temperature" :disabled="isPromptStreaming" label="Temperature" type="number" @input="onChange" />
      <TextField v-model="localItem.max_tokens" :disabled="isPromptStreaming" label="Max tokens" type="number" @input="onChange" />
      <div v-if="getFunctions(localItem.model)" class="functions">
        <Button color="primary" title="Functions" @click="toggleFunctions">
          <div>
            <MdIcon size="sm" name="function-variant" />
            {{ getFunctions(localItem.model).filter(f => f.selected).length }} attached
            <MdIcon size="sm" :name="functionsExpanded ? 'menu-up' : 'menu-down'" />
          </div>
        </Button>
        <div v-if="functionsExpanded" :class="{ expanded: functionsExpanded }" class="list">
          <ul>
            <li v-for="func in getFunctions(localItem.model)" :key="func.name" class="function">
              <Checkbox v-model="func.selected" @change="s => functionChecked(func.name, s)" />
              <span>
                <label> {{ func.name }} </label>({{ !!func.userDescritpion ? func.userDescritpion : func.description }})
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="prompt-body-content">
      <div v-for="(message, messageIndex) in localItem.messages" :key="messageIndex" class="template-content">
        <div class="head">
          <span class="label">{{ message.role }}:</span>
          <Button v-if="messageIndex === localItem.messages.length - 1 && localItem.messages.length > 2" color="error" @click="deleteMessage">
            <MdIcon size="sm" name="delete" />
          </Button>
        </div>
        <Code
          v-model="message.content"
          :read-only="!$hasPermission('prompts.write') || isPromptStreaming"
          :language="'plaintext'"
          :suggestions="suggestions"
          class="editor"
          :auto-height="true"
          @input="onChange"
        />
        <ImageVariable v-if="hasImageAttachments(message)" v-model="message.supplementals.images" label="Image examples" />
      </div>
      <div class="buttons">
        <Button color="primary" :disabled="isPromptStreaming" @click="addMessage">+ Message</Button>
        <div v-if="imageVariables && imageVariables.length" class="attachments">
          <Button color="primary" title="Attached variables" :disabled="isPromptStreaming" @click="toggleAttach">
            <div>
              <MdIcon size="sm" name="paperclip" class="attachments" />
              <MdIcon size="sm" :name="attachmentsExpanded ? 'menu-up' : 'menu-down'" class="attachments" />
            </div>
          </Button>
          <div v-if="attachmentsExpanded" class="attachemnts-pop-up" :class="{ expanded: attachmentsExpanded }">
            <ul>
              <li>Images:</li>
              <li v-for="attachment in imageVariables" :key="attachment.name" class="attachment">
                <Checkbox v-model="attachment.selected" @change="s => attachmentChecked(attachment.name, s)" />
                <label>{{ attachment.name || attachment.label }}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Code from '@/components/common/Code';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';
import TextField from '@/components/common/TextField';
import MdIcon from '@/components/common/MdIcon';
import Checkbox from '@/components/common/Checkbox';
import ImageVariable from '../variables/ImageVariable';

export default {
  components: {
    ImageVariable,
    Code,
    Multiselect,
    TextField,
    Button,
    MdIcon,
    Checkbox
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    suggestions: {
      type: Array,
      required: true
    },
    variables: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localItem: {
        ...this.item,
        messages: this.item.messages.map(message => ({
          ...message,
          supplementals: {
            ...(message.supplementals ?? { images: [] })
          }
        })),
        attachments: this.item.attachments ?? [],
        functions: this.item.functions ?? []
      },
      expanded: false,
      attachmentsExpanded: false,
      functionsExpanded: false,
      editingTitle: false
    };
  },
  computed: {
    ...mapState({
      models: s => s.nlp.models || [],
      providers: state => state.nlp.providers || [],
      isPromptStreaming: s => s.prompts.sample.isRequestPending,
      active: s => !s.prompts.sample.isRequestPending
    }),
    selectedModel() {
      return this.models.find(m => m.id === this.localItem.model);
    },
    isVisionModel() {
      return this.selectedModel?.capability.endsWith('vision') ?? false;
    },
    imageVariables() {
      return this.variables
        .filter(v => v.type === 'image')
        .filter(v => v.name && v.name.length)
        .map(v => ({
          name: v.name,
          selected: this.localItem.attachments.includes(v.name)
        }));
    },
    modelsOptions() {
      return this.models.map(m => m.id);
    }
  },
  watch: {
    'localItem.model': {
      handler() {
        const functions = this.getFunctions(this.localItem.model);
        if (!functions) {
          this.functionsExpanded = false;
          this.localItem.functions = [];
        }
      }
    }
  },
  created() {
    this.localItem.model = this.modelBackwardCompatibility(this.localItem.model);
  },
  methods: {
    hasImageAttachments(message) {
      if (!this.isVisionModel || message.role !== 'user') {
        return false;
      }

      const userExamples = this.localItem.messages.filter(m => m.role === 'user');

      return userExamples.length - 1 > userExamples.indexOf(message);
    },
    toggleFunctions() {
      this.functionsExpanded = !this.functionsExpanded;
    },
    getFunctions(modelId) {
      if (!modelId) {
        return [];
      }

      const model = this.models.find(m => m.id == modelId);

      if (!model) {
        return undefined;
      }

      const provider = this.providers?.find(p => p.name === model.provider);

      if (provider?.functions?.length) {
        return provider?.functions.map(f => ({
          ...f,
          selected: this.localItem.functions?.includes(f.name) ?? false
        }));
      }

      return undefined;
    },
    functionChecked(name, checked) {
      if (checked) {
        this.localItem.functions.push(name);
      } else {
        var index = this.localItem.functions.indexOf(name);
        this.localItem.functions.splice(index, 1);
      }

      this.$emit('change', { ...this.localItem });
    },
    attachmentChecked(name, checked) {
      if (checked) {
        this.localItem.attachments.push(name);
      } else {
        var index = this.localItem.attachments.indexOf(name);
        this.localItem.attachments.splice(index, 1);
      }
    },
    modelBackwardCompatibility(candidate) {
      var value = this.models.find(m => m.id == candidate);

      if (value) {
        return value.id;
      }

      for (const md of this.models) {
        if (!md.config) {
          continue;
        }
        var config = JSON.parse(md.config);
        if (config.deployment == candidate) {
          return md.id;
        }
      }

      return null;
    },
    getLabel(id) {
      return this.models.find(m => m.id === id)?.name;
    },
    onChange() {
      this.$emit('change', { ...this.localItem });
    },
    addMessage() {
      this.localItem.messages.push({
        role: this.localItem.messages.length % 2 === 1 ? 'user' : 'assistant',
        content: ''
      });
      this.onChange();
    },
    toggleAttach() {
      this.attachmentsExpanded = !this.attachmentsExpanded;
    },
    deleteMessage() {
      this.localItem.messages.pop();
      this.onChange();
    }
  }
};
</script>

<style scoped lang="scss">
.accordion-item {
  .accordion-item-header {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    align-content: center;
    min-height: 50px;
    height: 100%;
    background-color: var(--theme-surface);
    border-bottom: 1px solid var(--theme-on-surface);
    padding: 0 10px;

    .header-buttons {
      display: flex;

      .actions {
        display: flex;
        padding-right: 5px;
      }

      .ordering {
        display: grid;
        padding: 0 10px;
        grid-template-rows: 1fr 1fr;

        i {
          gap: 0;
          padding: 0;
          margin: 0;
          height: 18px;
          width: 18px;
          font-size: 18px;
        }

        i:first-child {
          justify-self: flex-start;
        }

        i:last-child {
          justify-self: flex-end;
        }
      }
    }
  }

  .title {
    margin-left: 10px;
  }

  .button {
    padding: 2px;
  }

  .active {
    cursor: pointer;
    :hover {
      background-color: var(--theme-on-surface);
      color: var(--theme-surface);
      border-radius: 2px;
    }
  }

  .accordion-item-content {
    padding: 10px;

    .settings {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;

      .functions {
        grid-column: 1 / span 3;
        gap: 10px;
        padding: 10px;
        padding: 5px;

        .list {
          background-color: var(--theme-surface);
          padding: 5px;
          .function {
            display: flex;
            align-content: flex-start;
            gap: 10px;
            font-size: 0.85rem;
            padding: 5px;
            padding-left: 0;
            min-width: 150px;
            background-color: var(--theme-surface);

            label {
              font-weight: bold;
              color: var(--theme-on-surface-accent);
            }
          }
        }
      }

      .expanded {
        display: flex;
        right: 0;
      }
    }

    .prompt-body-content {
      padding: 10px;

      .template-content {
        margin: 10px 0;
        display: grid;
        gap: 10px;

        .image-example {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-items: stretch;
        }

        .label {
          font-weight: 500;
          font-size: 0.75rem;
          letter-spacing: 0.025em;
        }

        .editor {
          height: 200px;
        }

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;

        .attachments {
          position: relative;

          .attachemnts-pop-up {
            display: none;
            gap: 5px;
            padding: 10px;
            border: 1px solid white;
            background-color: var(--theme-surface);

            .attachment {
              display: flex;
              align-content: flex-start;
              gap: 5px;
              font-size: 0.85rem;
              padding: 5px;
              padding-left: 0;
              min-width: 150px;
            }
          }

          .expanded {
            display: flex;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
}
</style>
