<template>
  <section class="create-user">
    <header>
      <h1>New User</h1>
    </header>
    <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
      <div class="form-row">
        <p-text-field v-model="firstName" :label="'First name'" autocomplete="off" />
        <div v-if="!$v.firstName.required" class="error">First name is required</div>
      </div>
      <div class="form-row">
        <p-text-field v-model="lastName" :label="'Last name'" autocomplete="off" />
        <div v-if="!$v.lastName.required" class="error">Last name is required</div>
      </div>
      <div class="form-row">
        <p-text-field v-model="email" :label="'Email'" autocomplete="off" />
        <div v-if="!$v.email.required" class="error">Email is required</div>
        <div v-if="!$v.email.email" class="error">Email has invalid format</div>
      </div>
      <div class="form-row">
        <div style="display: grid; grid-template-columns: 1fr max-content">
          <p-text-field v-model="password" :label="'Password'" type="text" autocomplete="new-password" />
          <p-button type="button" style="place-self: end" title="Randomize password" @click.prevent="randomizePassword">
            <p-md-icon name="form-textbox-password"></p-md-icon>
          </p-button>
        </div>
        <div v-if="!$v.password.required" class="error">Password is required</div>
        <div v-if="!$v.password.minLength" class="error">Password must be at least {{ $v.password.$params.minLength.min }} characters long.</div>
      </div>
      <div class="form-row">
        <p-tags v-model="assistants" :disabled="!$hasPermission('users.write')" label="Assistants" placeholder="" :options="suggestMember" />
      </div>
      <div class="form-row">
        <div style="display: flex; align-items: center;">
          <p-checkbox v-model="isBot" label="Is Bot" @change="handleBotChange"></p-checkbox>
          <span
            v-if="foundScriptMessage"
            class="tooltip-icon"
            @mouseenter="showTooltip = true"
            @mouseleave="showTooltip = false"
          >
            <p-md-icon name="information-outline" color="warning" size="sm" />
            <div v-if="showTooltip" class="tooltip">
              {{ foundScriptMessage }}
            </div>
          </span>
        </div>
      </div>
      <div v-if="isBot" class="form-row">
        <p-multiselect
          v-model.trim="script"
          :options="scripts"
          :label="'Script'"
          :searchable="true"
          placeholder="Select script"
        />
      </div>
      <div v-if="isBot && script" class="form-row">
        <p-version-multiselect
          v-model="version"
          :label="'Version'"
          :options="versions"
          placeholder="Select a version"
        />
      </div>
      <div v-if="isCreateRequestFailed" class="error">Failed to create a new user. Check your input and try again.</div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button color="primary" type="submit" :disabled="isCreateRequestPending || ($v.$anyDirty && $v.$invalid)">Save</p-button>
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';
import { required, minLength, email } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import Tags from '@/components/common/Tags';
import CheckBox from '@/components/common/Checkbox.vue';
import Multiselect from '@/components/common/Multiselect';
import VersionMultiselect from '@/components/common/VersionMultiselect.vue';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-md-icon': MdIcon,
    'p-tags': Tags,
    'p-checkbox': CheckBox,
    'p-multiselect': Multiselect,
    'p-version-multiselect': VersionMultiselect,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      isBot: false,
      assistants: [],
      scripts: [],
      script: null,
      version: null,
      versions:[],
      isVersionsLoading: false,
      foundScriptMessage: '',
      showTooltip: false,
    };
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.users.isCreateRequestPending,
      isCreateRequestFailed: s => s.users.isCreateRequestFailed,
      practitioners: s => s.portfolio.practitioners.collection
    }),
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    firstName: {
      required
    },
    lastName: {
      required
    }
  },
  async created() {
    await this.$store.dispatch('portfolio/practitioners/getCollection');
  },
  watch: {
    script(newScript) {
      if (newScript) {
        this.version = null;
        this.versions = [];
        this.getVersion(newScript);
      }
    },
    isBot(value) {
      if (!value) {
        this.script = null;
        this.version = null;
        this.foundScriptMessage = '';
      }
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const user = await this.$store.dispatch('users/create', {
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim(),
          email: this.email.trim(),
          password: this.password,
          isBot: this.isBot,
          assistants: this.assistants,
          scriptKey: this.version?.Key || null
        });

        if (this.isBot && this.newScript) {
          await this.$store.dispatch('bots/uploadScript', {
            email: user.email,
            script: this.newScript
          });
        }

        this.$router.push({ path: `/people` });
      } catch (error) {
        const { message } = JSON.parse(await error.response.text());
        this.$toast.error({
          title: 'Create failed',
          message: message || `Please, try again later or contact our development team.`
        });
      }
    },
    back() {
      this.$router.back();
    },
    randomizePassword() {
      this.password = Math.random()
        .toString(36)
        .substring(2);
    },
    async suggestMember(s) {
      if (s?.length) {
        return httpClient.get('/api/auth/suggest/users?q=' + s);
      } else {
        return [];
      }
    },
    async handleBotChange() {
      if (this.isBot) {
        this.scripts = await httpClient.get('/api/auth/bots/bot/scripts');
        const suggestedScript = Array.isArray(this.scripts) && this.email?.includes('@') 
          ? this.scripts.find(
              s => s.toLowerCase() === this.email.split('@')[0].toLowerCase()
            ) || null
          : null;

        if (suggestedScript) {
          this.foundScriptMessage = `We found "${suggestedScript}" as a script candidate based on the email.`;
        } else {
          this.foundScriptMessage = '';
        }

        this.script = suggestedScript;
      }
    },
    async getVersion(script) {
      this.isVersionsLoading = true;
      const versions = await httpClient.get(`/api/auth/bots/${script}/versions`);
      this.versions = versions
        .filter(v => v.Key.endsWith('js'));
      this.version = this.versions[0];
      this.isVersionsLoading = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.create-user {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  .submit {
    margin-top: 25px;
  }

  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }

    .tooltip-icon {
      margin-left: 8px;
      cursor: pointer;
      font-size: 1rem;
      position: relative;
    }

    .tooltip {
      position: absolute;
      top: -1.5rem;
      left: 0;
      background: var(--theme-warning);
      color: var(--theme-on-background);
      padding: 0.5rem;
      border-radius: 4px;
      font-size: 0.85rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      white-space: nowrap;
      z-index: 100;
    }
  }
}
</style>
