<template>
  <div class="root">
    <div>
      <label>Source:</label>
      <Multiselect v-model="localItem.source" :disabled="isPromptStreaming" :options="contentSources" @change="onChange" />
    </div>
    <div>
      <div v-if="localItem.source === 'file'" class="file-source">
        <FileSelect accept=".docx,.vsdx,.png,.jpeg,.jpg,.svg" :multiple="true" variant="contained" @change="fileSelected">
          <MdIcon size="md" name="file-word" style="margin-right: 3px" />Select File(s)
        </FileSelect>
        <div class="preview">
          <div v-for="file in localItem.value" :key="file.name">
            <div class="file">
              <div class="thumbnail">
                {{ file.name }}
              </div>
              <Button class="button" title="Remove" @click="clear(file)">
                <MdIcon size="md" name="close" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="localItem.source === 'mermaid'" class="mermaid-source">
        <label>Template:</label>
        <Code
          v-model="localItem.value.template"
          :read-only="!$hasPermission('prompts.write') || isPromptStreaming"
          :language="'plaintext'"
          class="editor"
          :suggestions="suggestions"
          :auto-height="true"
          @input="onChange"
        />
      </div>
      <div v-else>
        NOT IMPLEMENTED
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Multiselect from '@/components/common/Multiselect';
import FileSelect from '@/components/common/FileSelect';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import Code from '@/components/common/Code';

export default {
  components: {
    Multiselect,
    FileSelect,
    Button,
    MdIcon,
    Code
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    suggestions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      localItem: {
        ...this.item
      }
    };
  },
  computed: {
    ...mapState({
      contentSources: s => s.nlp.contentSources,
      isPromptStreaming: s => s.prompts.sample.isRequestPending
    })
  },
  watch: {
    'localItem.source': {
      handler(newValue, oldValue) {
        switch (newValue) {
          case 'mermaid':
          case 'ai':
            this.localItem.value = {
              template: ''
            };
            break;
          case 'file':
            this.localItem.value = [];
            break;
        }
      },
      deep: true
    }
  },
  methods: {
    onChange() {
      this.$emit('change', { ...this.localItem });
    },
    async fileSelected(files) {
      const loadedFiles = [];
      for (const file of files) {
        if (/(docx)|(vsdx)|(jpg)|(jpeg)|(png)|(svg)$/.test(file.name)) {
          this.progress = `Loading ${file.name}...`;
          const loadedFile = await new Promise(async function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = () => {
              resolve({
                name: file.name,
                type: reader.result.match(/data:(.*?);base64/)[1],
                base64: reader.result
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
          loadedFiles.push(loadedFile);
        }
      }

      this.localItem.value = loadedFiles;

      this.onChange();
    },
    clear(file) {
      if (Array.isArray(this.localItem.value)) {
        const index = this.localItem.value.indexOf(file);
        this.localItem.value.splice(index, 1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.root {
  display: grid;
  gap: 10px;
  grid-template-rows: auto auto 1fr;

  label {
    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: 0.025em;
  }

  .mermaid-source {
    display: grid;
    gap: 10px;
  }

  .file-source {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
    border: 1px solid var(--theme-on-background-dark);
    border-radius: 2px;
    padding: 2px;

    .file {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      div:not(:last-child) {
        margin-bottom: 1px;
      }

      .thumbnail {
        z-index: 0;
        padding: 2px;
        border-color: 1px white;

        img {
          max-height: 75px;
        }
      }

      .file {
        display: flex;
        align-items: flex-start;
        position: relative;

        .button {
          visibility: hidden;
          right: 0;
          padding: 0;
          z-index: 1000;
          cursor: pointer;
          color: var(--theme-on-surface);
          border-radius: 2px;
        }
      }
      .file:hover .button {
        visibility: visible;
      }
    }
  }
}
</style>
